input, textarea {
    display: block;
    padding: 1em;
    width: 25%;
    min-width: 250px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2em;
    border: 2px solid #FF5F00;
    border-radius:4px;
}

textarea {
    width: 50%;
}

.prev-img {
    cursor: pointer;
    max-height: 12em;
    width: auto;
}

.removeDiv {
    border: 1px solid black;
    text-align: center;
}

.rowDiv {
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
}