.App {
  text-align: center;
  margin:5px;
}

.ciee-logo {
  margin-bottom: 10px;
  width:25%;
}

@media (orientation: portrait) {
  .ciee-logo {
    width: 75%;
  }
}