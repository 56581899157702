.homepage-links {
    background-color: #005CB9;
    border: 4px solid #FF5F00;
    padding: 5% 5%;
    accent-color: #FF5F00;
    font-size: 5em;
    font-family: Optima;
    color: white;
    text-decoration: none;
    min-height: 50vh;
    width: 43vw;
    border-radius: 30px;
    margin: auto;
    display: grid;
    align-items: center;
    text-align: center;
}

.homepage-links:hover {
    background-color: #0256a8;
}

.homepage-links:active {
    background-color: #004381;
}

.homepage-parent {
    display:grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
}

@media (orientation: portrait) {
    .homepage-parent {
        display: grid;
        grid-auto-columns: minmax(0, 1fr);
        grid-template-columns: 0;
        width: 100vw;
        grid-gap: 0.75em;
    }
    .homepage-links
    {
        background-color: #005CB9;
        border: 4px solid #FF5F00;
        padding: 5% 5%;
        align-items: center;
        accent-color: #FF5F00;
        font-size: 5em;
        font-family: Optima;
        color: white;
        text-decoration: none;
        width: 97vw;
        min-height: auto;
    }
}