.things-to-do-parent {
    justify-content: center;
    width:100%;
    font-family: Optima;
}

.container
{
        width: 200px;
        padding: 20px;
        background: #eee;
        border: 1px solid black;
}

@media (orientation: landscape) {
    .things-to-do-parent {
        display: inline-block;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        grid-gap: 2%;
    }
}