.page-management {
    border: 3px solid #FF5F00;
    display: inline;
}

.Edit Post
{
    border: 3px solid #FF5F00;
    display: inline;
    width: 5vw;
    height: 5vh;
}

button {
    margin: .2em;
}

.removePostButton {
    color: black;
    fontFamily: "Verdana";
    width: 45%;
    height: 20px;
}

.editPostButton {
    color: black;
    fontFamily: "Verdana";
    width: 45%;
    height: 20px;
}