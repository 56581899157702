body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  margin:5px;
}

.ciee-logo {
  margin-bottom: 10px;
  width:25%;
}

@media (orientation: portrait) {
  .ciee-logo {
    width: 75%;
  }
}
.homepage-links {
    background-color: #005CB9;
    border: 4px solid #FF5F00;
    padding: 5% 5%;
    accent-color: #FF5F00;
    font-size: 5em;
    font-family: Optima;
    color: white;
    text-decoration: none;
    min-height: 50vh;
    width: 43vw;
    border-radius: 30px;
    margin: auto;
    display: grid;
    align-items: center;
    text-align: center;
}

.homepage-links:hover {
    background-color: #0256a8;
}

.homepage-links:active {
    background-color: #004381;
}

.homepage-parent {
    display:grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
}

@media (orientation: portrait) {
    .homepage-parent {
        display: grid;
        grid-auto-columns: minmax(0, 1fr);
        grid-template-columns: 0;
        width: 100vw;
        grid-gap: 0.75em;
    }
    .homepage-links
    {
        background-color: #005CB9;
        border: 4px solid #FF5F00;
        padding: 5% 5%;
        align-items: center;
        accent-color: #FF5F00;
        font-size: 5em;
        font-family: Optima;
        color: white;
        text-decoration: none;
        width: 97vw;
        min-height: auto;
    }
}
.managed-pages {
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2%;
    justify-content: space-evenly;
}
.page-management {
    border: 3px solid #FF5F00;
    display: inline;
}

.Edit Post
{
    border: 3px solid #FF5F00;
    display: inline;
    width: 5vw;
    height: 5vh;
}

button {
    margin: .2em;
}

.removePostButton {
    color: black;
    fontFamily: "Verdana";
    width: 45%;
    height: 20px;
}

.editPostButton {
    color: black;
    fontFamily: "Verdana";
    width: 45%;
    height: 20px;
}
input, textarea {
    display: block;
    padding: 1em;
    width: 25%;
    min-width: 250px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2em;
    border: 2px solid #FF5F00;
    border-radius:4px;
}

textarea {
    width: 50%;
}

.prev-img {
    cursor: pointer;
    max-height: 12em;
    width: auto;
}

.removeDiv {
    border: 1px solid black;
    text-align: center;
}

.rowDiv {
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
}
input, textarea {
    display: block;
    padding: 1em;
    width: 25%;
    min-width: 250px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2em;
    border: 2px solid #FF5F00;
    border-radius:4px;
}

textarea {
    width: 50%;
}

.prev-img {
    cursor: pointer;
    max-height: 12em;
    width: auto;
}

.removeDiv {
    border: 1px solid black;
    text-align: center;
}

.rowDiv {
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
}
.postDiv {
    border: 1px solid black;
    flex: 1 1;
    margin-bottom: 0.5em;
    border-radius: 0.5em;
}

.postDiv:hover {
    cursor: pointer;
}
.postDiv {
    border: 1px solid black;
}
.md-content {
    text-align: left;
    margin: 2.5% 5% 5%;
}

table, tr, td, th {
    border: 1px solid black;
}

img {
    display: block;
    max-width: 30em;
    max-height: 30em;
    margin-right: auto;
    margin-left: auto;
}

iframe {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

@media (orientation: portrait) {
    .md-content {
        margin: 2.5% 5% 10%;
    }

    iframe {
        width: 90%;
    }

}
.things-to-do-parent {
    justify-content: center;
    width:100%;
    font-family: Optima;
}

.container
{
        width: 200px;
        padding: 20px;
        background: #eee;
        border: 1px solid black;
}

@media (orientation: landscape) {
    .things-to-do-parent {
        display: inline-block;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        grid-gap: 2%;
    }
}
