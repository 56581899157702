.md-content {
    text-align: left;
    margin: 2.5% 5% 5%;
}

table, tr, td, th {
    border: 1px solid black;
}

img {
    display: block;
    max-width: 30em;
    max-height: 30em;
    margin-right: auto;
    margin-left: auto;
}

iframe {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

@media (orientation: portrait) {
    .md-content {
        margin: 2.5% 5% 10%;
    }

    iframe {
        width: 90%;
    }

}